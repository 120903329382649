import styled from 'styled-components'

const Description = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 1.661;
  letter-spacing: 2px;
  text-align: justify;
`

export default Description
