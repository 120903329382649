import styled from 'styled-components'

const BoxText = styled.h1`
  color: #fff;
  font-size: 28px;
  line-height: 1.25;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

export default BoxText
