import React from 'react'
import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BlogBanner from '../../components/blocks/BlogBanner'

const Container = styled.div`
  ${grid({})};
  padding: 0 60px;
  margin-top: 40px;
  margin-bottom: 103px;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-bottom: 63px;
  }
`

const Header = () => (
  <Container>
    <GridUnit size={{ sm: 1, md: 7 / 16 }}>
      <BlogBanner.Box>
        <BlogBanner.BoxText>Blog</BlogBanner.BoxText>
        <BlogBanner.BoxBottomText>
          Interior-Trends & Inspirationen
        </BlogBanner.BoxBottomText>
      </BlogBanner.Box>
    </GridUnit>
    <GridUnit size={{ sm: 1 / 16 }} />
    <GridUnit size={{ sm: 1, md: 7 / 16 }}>
      <BlogBanner.Header>
        Lass dich inspirieren von den neuesten Interior-Trends und
        Handverlesenen Kolletionen
      </BlogBanner.Header>
      <BlogBanner.Description>
        Wir von amber living sind stets auf der Suche nach Inspirationen, die
        unsere Interior-Passion beflügeln. Unsere Entdeckungen wollen wir gern
        mit dir teilen: Erfahre hier mehr über aktuelle Einrichtungstrends,
        Lieblingsstücke, die uns am Herzen liegen und lies in unserem Ratgeber
        von Tipps & Tricks, die dein Zuhause noch schöner machen.
      </BlogBanner.Description>
    </GridUnit>
    <BlogBanner.FakeDiv />
  </Container>
)

export default Header
