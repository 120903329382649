import styled, { css } from 'styled-components'

const Gradient = css`
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
`

const Description = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 1.661;
  letter-spacing: 1.26px;
  text-align: justify;
  margin-bottom: 1rem;
  height: 100px;
  overflow: hidden;

  p {
    display: inline;
    padding-right: 10px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    ${Gradient}
  }
`

export default Description
