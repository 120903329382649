import styled from 'styled-components'

const AffectAllImage = styled.div`
  img {
    position: relative;
    z-index: 2;
    padding-top: 15px;
    max-width: 100%;
    @media (max-width: 2565px) and (min-width: 1400px) {
      width: 100%;
    }
  }
`

export default AffectAllImage
