import { Link } from 'gatsby'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BlogItem from '../../components/blocks/BlogItem'
import BlogItemButton from '../../components/blocks/BlogItemButton'
import LoadMore from './LoadMore'

const Markdown = require('react-markdown')

const Container = styled.div`
  ${grid({})};
  padding: 0 60px;
  margin-top: 40px;
  margin-bottom: 103px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    padding: 0 100px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px;
  }
`

const BlogItemWrapper = styled.div`
  padding: 0 10px;
`

const HeadingRenderer = props => {
  const Heading = Markdown.renderers.paragraph
  return <Heading {...props} />
}

const ItemList = ({ pageContext }) => {
  const [displayedBlogs, setDisplayedBlogs] = useState(10)
  const blogItems = pageContext.contentful.data.allContentfulLandingPage.edges.concat(
    pageContext.contentful.data.allContentfulTrendPage.edges
  )

  const BlogImage = item => {
    if (!item?.node?.mediaImage1) return null

    if (item.node.image1Clickid) {
      return (
        <Link
          to={
            item.node.image1Clickid.indexOf('/') >= 0
              ? item.node.image1Clickid
              : '/clickout?click_id=' + item.node.image1Clickid
          }
          aria-label={'link to ' + item.node.image1Title}
        >
          <BlogItem.Image fluid={item.node.mediaImage1.fluid} />
        </Link>
      )
    }
    return <BlogItem.Image fluid={item.node.mediaImage1.fluid} />
  }

  return (
    <Container>
      {pageContext.posts.slice(0, displayedBlogs).map(post => {
        const item = blogItems.find(e => e.node.slug === post.page_slug)
        item.node.author = item.node.author ? item.node.author : '...'
        return (
          <GridUnit size={{ xs: 1, md: 1 / 2 }} key={item.node.contentful_id}>
            <BlogItemWrapper>
              <BlogItem>
                <BlogImage {...item} />
                <BlogItem.Title>
                  <BlogItem.StyledLink as={Link} to={'/' + post.page_url}>
                    {item.node.header}
                  </BlogItem.StyledLink>
                </BlogItem.Title>
                <BlogItem.Subtitle>
                  <BlogItem.StyledLink as={Link} to={'/' + post.page_url}>
                    {item.node.headerSubtitle}
                  </BlogItem.StyledLink>
                </BlogItem.Subtitle>
                <BlogItem.Description>
                  <ReactMarkdown
                    source={item.node.textBlock1.textBlock1}
                    renderers={{ heading: HeadingRenderer }}
                  />
                </BlogItem.Description>
                <BlogItem.Author>Posted by {item.node.author}</BlogItem.Author>
                <BlogItemButton as={Link} to={'/' + post.page_url}>
                  <BlogItemButton.Text>Mehr Lesen</BlogItemButton.Text>
                </BlogItemButton>
              </BlogItem>
            </BlogItemWrapper>
          </GridUnit>
        )
      })}
      <LoadMore
        isEnd={displayedBlogs >= pageContext.posts.length}
        visibleItems={displayedBlogs}
        setVisibleItems={setDisplayedBlogs}
      />
    </Container>
  )
}

export default ItemList
