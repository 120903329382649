import styled from 'styled-components'

const BoxBottomText = styled.p`
  bottom: 11px;
  color: #fff;
  font-size: 13px;
  left: 32px;
  letter-spacing: 3.6px;
  position: absolute;
  top: auto;
  line-height: 1.661;
  font-weight: 400;

  @media (max-width: 575px) {
    width: 100%;
    letter-spacing: 3px;
  }
`

export default BoxBottomText
