import styled from 'styled-components'
import AffectAllImage from './AffectAllImage'
import Box from './Box'
import BoxBottomText from './BoxBottomText'
import BoxText from './BoxText'
import Description from './Description'
import FakeDiv from './FakeDiv'
import Header from './Header'
import Image from './Image'

const BlogBanner = styled.section`
  position: relative;
  margin: 40px auto 0 auto;
  padding-right: 75px;
  padding-bottom: 50px;
  padding-left: 75px;

  @media (max-width: 767px) and (min-width: 320px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

BlogBanner.FakeDiv = FakeDiv
BlogBanner.Box = Box
BlogBanner.Image = Image
BlogBanner.AffectAllImage = AffectAllImage
BlogBanner.BoxText = BoxText
BlogBanner.BoxBottomText = BoxBottomText
BlogBanner.Header = Header
BlogBanner.Description = Description

export default BlogBanner
