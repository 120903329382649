import styled from 'styled-components'

const Text = styled.span`
  background: transparent;
  display: inline-block;
  font-size: 12px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
  line-height: 0.75;
  transition: 700ms all;
  color: #fff;

  &:before {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 1px;
    left: -10px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 5px;
    transition: all ease 700ms;
  }

  &:after {
    background-color: #fff;
    bottom: 0;
    content: '';
    height: 1px;
    right: -10px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 5px;
    left: auto;
    transition: all ease 700ms;
  }
`

export default Text
