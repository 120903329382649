import styled from 'styled-components'

const Box = styled.div`
  height: 255px;
  width: 100%;
  background-color: #000000;
  float: left;
  padding: 24px 24px 0 24px;
  text-align: left;
  position: relative;
  @media (max-width: 767px) and (min-width: 576px) {
    width: 100%;
    height: 175px;
  }
  @media (max-width: 575px) {
    width: 100%;
    height: 105px;
  }
`

export default Box
