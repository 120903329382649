import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  position: relative;
  z-index: 2;
  padding-top: 15px;
  max-width: 100%;
  @media (max-width: 2565px) and (min-width: 1400px) {
    width: 100%;
  }
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
