import styled from 'styled-components'

const Author = styled.p`
  color: #000;
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
  letter-spacing: 3.9px;
  margin-bottom: 13px;
  box-sizing: border-box;
`

export default Author
