import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import Layout from '../../components/layouts/layout'
import Header from './Header'
import ItemList from './ItemList'

const IndexPage = props => {
  return (
    <>
      <Helmet
        title={props.pageContext.data?.seo_title}
        meta={[
          {
            name: 'description',
            content: props.pageContext.data?.seo_description
          },
          {
            name: 'keywords',
            content: props.pageContext.data?.keywords
          },
          {
            name: 'robots',
            content: props.pageContext.data?.index
              ? props.pageContext.data?.index
              : 'index, follow'
          }
        ]}
      />
      <Layout {...props} navigation={props.pageContext.navigation}>
        <Header />
        <ItemList {...props} />
      </Layout>
    </>
  )
}

export default IndexPage
