import styled from 'styled-components'

const AffectAllImage = styled('div')`
  img {
    position: relative;
    width: 100% !important;
    max-width: 100%;
    max-height: 270px;
    height: auto;
    object-fit: contain;
  }
`

export default AffectAllImage
