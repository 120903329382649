import styled from 'styled-components'
import Text from './Text'

const BlogItemButton = styled.a`
  background-color: #000;
  height: 30px;
  text-align: center;
  width: 190px;
  color: #fff;
  padding: 0 10px;
  position: relative;
  line-height: 0.75;
  margin-top: 12px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 700ms;
  text-decoration: none;

  &:hover {
    background-color: #fff;
    color: #000;

    ${Text} {
      color: #000;

      &:before {
        background-color: #000;
      }
      &:after {
        background-color: #000;
      }
    }
  }
`
BlogItemButton.Text = Text

export default BlogItemButton
