import styled from 'styled-components'

const Header = styled.h2`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 3.6px;
  line-height: 1.5;
  margin-bottom: 20px;
  &:first-child {
    margin-top: 50px;
  }
`

export default Header
