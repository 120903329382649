import styled from 'styled-components'

const LoadMoreButton = styled.a`
  position: relative;
  line-height: 0.75;
  text-decoration: none;
  z-index: 2;
  font-size: 12px;
  cursor: pointer;
  background-color: #000;
  text-align: center;
  width: 190px;
  color: #fff;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 700ms;

  height: 30px;

  &:before,
  &:after {
    background-color: #000;
    bottom: 0;
    content: '';
    height: 1px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 5px;
    transition: all ease 700ms;
    opacity: 0;
  }

  &:before {
    left: -10px;
    top: 0;
    width: 5px;
  }

  &:after {
    right: -10px;
    left: auto;
  }

  &:hover {
    background-color: #fff;
    color: #000;

    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 1;
    }
  }
`

export default LoadMoreButton
