import styled from 'styled-components'
import StyledLink from './StyledLink'

const Title = styled.h2`
  line-height: 1.2;
  margin-bottom: 12px;
  margin-top: 53px;
  ${StyledLink} {
    color: #000;
    font-size: 26px;
    letter-spacing: 5px;
    line-height: 1.1;
    font-weight: 700;
    text-decoration: none;

    @media screen and (max-width: 1199px) {
      letter-spacing: 3px;
      line-height: 1.2;
    }
  }
`

export default Title
