import styled from 'styled-components'
import StyledLink from './StyledLink'

const Subtitle = styled.div`
  margin-bottom: 22px;

  ${StyledLink} {
    line-height: 0.9;
    font-weight: 700;
    color: #757575;
    font-family: 'Merriweather', serif;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 3.6px;
  }
`

export default Subtitle
