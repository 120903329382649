import GatsbyImage from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'

const Img = styled(GatsbyImage)`
  position: relative;
  width: 100% !important;
  max-width: 100%;
  max-height: 270px;
  height: auto;
  object-fit: contain;
`

const Image = props => <Img {...props} className='lazyload' />

export default Image
