import styled from 'styled-components'
import AffectAllImage from './AffectAllImage'
import Author from './Author'
import Description from './Description'
import Image from './Image'
import StyledLink from './StyledLink'
import Subtitle from './Subtitle'
import Title from './Title'

const BlogItem = styled.div`
  margin-bottom: 80px;
`

BlogItem.Image = Image
BlogItem.Title = Title
BlogItem.Subtitle = Subtitle
BlogItem.Description = Description
BlogItem.Author = Author
BlogItem.StyledLink = StyledLink
BlogItem.AffectAllImage = AffectAllImage

export default BlogItem
