import React from 'react'
import styled from 'styled-components'
import LoadMoreButton from '../../components/elements/LoadMoreButton'

const _LIMIT_ = 10

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px auto;
`

const LoadMore = ({ isEnd, setVisibleItems, visibleItems }) => {
  if (isEnd) {
    return null
  }

  return (
    <LoadMoreContainer>
      <LoadMoreButton onClick={() => setVisibleItems(visibleItems + _LIMIT_)}>
        mehr anzeigen
      </LoadMoreButton>
    </LoadMoreContainer>
  )
}

export default LoadMore
