import styled from 'styled-components'

const FakeDiv = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 845px;
  height: 255px;
  background: #f4f4f4 none repeat scroll 0 0;
  z-index: -1;
`

export default FakeDiv
